import { api, createCrudService } from '@cargill/shared'

const service = createCrudService(
  '/api/carrierCostAndTransitTimeInformation',
  api
)

service.getValidationContext = async () =>
  (await api.get('/api/carrierCostAndTransitTimeInformation/validationContext'))
    ?.data

export default service
