import { 
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/carrierTerminalRestriction', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.carrier.options = ctx.carrierOptions
  fieldsById.stuffingTerminal.options = ctx.stuffingTerminalOptions
}

export default service
