<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import {
  CargillCrudMetaView,
  responseToOptions,
  helpers
} from '@cargill/shared'
import service from '../api/carrierService'
import carrierCapacityInformationService from '../api/carrierCapacityInformationService'
import carrierCostAndTransitTimeInformationService from '../api/carrierCostAndTransitTimeInformationService'
import carrierFreeTimeInformationService from '../api/carrierFreeTimeInformationService'
import carrierTerminalRestrictionService from '../api/carrierTerminalRestrictionService'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getFreeTimeInformationMeta() {
      const freeTimeInformationMeta =
        await carrierFreeTimeInformationService.getMeta()
      freeTimeInformationMeta.name = 'carrierFreeTimeInformation'
      freeTimeInformationMeta.masterName = 'carrierId'
      freeTimeInformationMeta.fields = freeTimeInformationMeta.fields.filter(
        (field) => field.id != 'carrier'
      )
      freeTimeInformationMeta.detailsObject = {
        service: carrierFreeTimeInformationService
      }
      return freeTimeInformationMeta
    },
    async getCarrierCostAndTransitTimeInformationMeta() {
      const carrierCostAndTransitTimeInformationMeta =
        await carrierCostAndTransitTimeInformationService.getMeta()
      carrierCostAndTransitTimeInformationMeta.name =
        'carrierCostAndTransitTimeInformation'
      carrierCostAndTransitTimeInformationMeta.masterName = 'carrierId'
      carrierCostAndTransitTimeInformationMeta.fields =
        carrierCostAndTransitTimeInformationMeta.fields.filter(
          (field) => field.id != 'carrier'
        )
      carrierCostAndTransitTimeInformationMeta.detailsObject = {
        service: carrierCostAndTransitTimeInformationService
      }

      const ctx =
        await carrierCostAndTransitTimeInformationService.getValidationContext()
      ctx.portOptions = responseToOptions(ctx.portOptions)
      ctx.shippingCostUnityOptions = responseToOptions(
        ctx.shippingCostUnityOptions
      )
      const originPortField =
        carrierCostAndTransitTimeInformationMeta.fields.find(
          (field) => field.id == 'originPort'
        )
      const destinationPortField =
        carrierCostAndTransitTimeInformationMeta.fields.find(
          (field) => field.id == 'destinationPort'
        )
      const shippingCostUnityField =
        carrierCostAndTransitTimeInformationMeta.fields.find(
          (field) => field.id == 'shippingCostUnity'
        )

      originPortField.options = ctx.portOptions
      destinationPortField.options = ctx.portOptions
      shippingCostUnityField.options = ctx.shippingCostUnityOptions

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const requiredMappings = {
          shippingCostUnity: 'shippingCost'
        }
        helpers.includeImpactedRequired(
          requiredMappings,
          validationSchema,
          translate
        )
        return yup.object().shape(validationSchema)
      }

      return carrierCostAndTransitTimeInformationMeta
    },
    async getCapacityInformationMeta() {
      const capacityInformationMeta =
        await carrierCapacityInformationService.getMeta()
      capacityInformationMeta.name = 'carrierCapacityInformation'
      capacityInformationMeta.masterName = 'carrierId'
      capacityInformationMeta.fields = capacityInformationMeta.fields.filter(
        (field) => field.id != 'carrier'
      )
      capacityInformationMeta.detailsObject = {
        service: carrierCapacityInformationService
      }

      const ctx = await carrierCapacityInformationService.getValidationContext()
      ctx.portOptions = responseToOptions(ctx.portOptions)
      ctx.unityOptions = responseToOptions(ctx.unityOptions)
      const originPortField = capacityInformationMeta.fields.find(
        (field) => field.id == 'originPort'
      )
      const shippingCapacityUnityField = capacityInformationMeta.fields.find(
        (field) => field.id == 'shippingCapacityUnity'
      )

      originPortField.options = ctx.portOptions
      shippingCapacityUnityField.options = ctx.unityOptions

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const requiredMappings = {
          shippingCapacityUnity: 'shippingCapacity'
        }
        helpers.includeImpactedRequired(
          requiredMappings,
          validationSchema,
          translate
        )
        helpers.createBaseLocalityValidations(validationSchema, translate)
        return yup.object().shape(validationSchema)
      }

      return capacityInformationMeta
    },
    async getTerminalRestrictionMeta() {
      const terminalRestrictionMeta = await carrierTerminalRestrictionService.getMetaWithValidation()
      terminalRestrictionMeta.name = 'carrierTerminalRestriction'
      terminalRestrictionMeta.masterName = 'carrierId'
      terminalRestrictionMeta.fields = terminalRestrictionMeta.fields.filter(
        (field) => field.id != 'carrier'
      )
      terminalRestrictionMeta.detailsObject = {
        service: carrierTerminalRestrictionService
      }
      return terminalRestrictionMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getCarrierCostAndTransitTimeInformationMeta(),
        this.getCapacityInformationMeta(),
        this.getFreeTimeInformationMeta(),
        this.getTerminalRestrictionMeta() 
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },

  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
